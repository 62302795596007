<template>
  <div class="p-2">
    <h1 class="text-2xl my-2">Les endroits</h1>
    <div v-for="position in positions" :key="position.name" class="rounded-xl shadow bg-gray-700 my-4 p-1 grid grid-cols-4">
      <div class="content-center">
        <img class="h-14 w-auto mx-auto" :src="position.image">
      </div>
      <div class="col-span-3 p-1">
        <div class="text-left text-lg font-semibold">
          {{ position.name }}
        </div>
        <div class="text-justify">
          {{ position.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Place",
  data () {
    return {
      positions: [
        {
          'name': 'Sur le bar',
          'description': 'Comptoir d\'un débit de boissons, où l\'on peut consommer debout ou assis sur des hauts tabourets.',
          'image': require('../assets/bar.png')
        },
        {
          'name': 'Dans le bac',
          'description': 'Un tonneau est un conteneur de révolution servant à conserver les liquides de consommation. Inventé par les Celtes, et plus précisément par les Gaulois, le tonneau a traversé les siècles.',
          'image': require('../assets/bac.png')
        },
        {
          'name': 'Toilette',
          'description': 'Le mot toilettes (nom féminin1) désigne le local appelé aussi cabinets, chiottes ou « petit coin » (ou en Belgique « la toilette » ou le binoche2) consacré à la discrétion et l\'intimité du moment de soulagement volontaire des déjections corporelles : urine et défécation principalement.',
          'image': require('../assets/toilette.png')
        },
        {
          'name': 'Sur la table',
          'description': 'Une table est un meuble composé d\'un plateau horizontal reposant sur un ou plusieurs pieds ou supports.',
          'image': require('../assets/table.png')
        },
        {
          'name': 'Sous la table',
          'description': 'Une table est un meuble composé d\'un plateau horizontal reposant sur un ou plusieurs pieds ou supports.',
          'image': require('../assets/table.png')
        },
        {
          'name': 'Dans la douche',
          'description': 'Installation permettant de prendre une douche',
          'image': require('../assets/douche.png')
        },
        {
          'name': 'Sur une chaise',
          'description': 'Chaise désigne un siège à dossier et qui ne possède pas de bras , qui n\'est pas pourvu d\'accoudoirs comme le sont les fauteuils.',
          'image': require('../assets/chaise.png')
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>
